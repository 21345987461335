<template>
  <div class="card">
    <div class="card-content">
      <div class="columns is-multiline">
        <div class="column is-12">
          <p class="font_18 has_lightgrey has-text-weight-semibold">
            Información general del usuario
          </p>
        </div>
        <template v-if="!isAdmin">
          <div class="column is-12">
            <p class="font_15 has_gray has-text-weight-bold">
              {{ userName }}
            </p>
          </div>

          <div class="column is-3">
            <p class="font_15 has_gray">
              Celular/Whatsapp:
            </p>
          </div>
          <div class="column is-3">
            <p class="font_15 has_gray has-text-weight-semibold">
              {{ phone }}
            </p>
          </div>

          <div class="column is-3">
            <p class="font_15 has_gray">
              Tipo de persona:
            </p>
          </div>
          <div class="column is-3">
            <p class="font_15 has_gray has-text-weight-semibold">
              {{ personTypeName }}
            </p>
          </div>

          <div class="column is-3">
            <p class="font_15 has_gray">
              Correo electrónico:
            </p>
          </div>
          <div class="column is-3">
            <p class="font_15 has_gray has-text-weight-semibold">
              {{ email }}
            </p>
          </div>

          <div class="column is-3">
            <p class="font_15 has_gray">
              Razón Social:
            </p>
          </div>
          <div class="column is-3">
            <p class="font_15 has_gray has-text-weight-semibold">
              {{ businessName || 'N/A' }}
            </p>
          </div>

          <div class="column is-3">
            <p class="font_15 has_gray">
              Estatus:
            </p>
          </div>
          <div class="column is-3">
            <p class="font_15 has_gray has-text-weight-semibold">
              {{ statusUser ? 'Activo' : 'Inactivo' }}
            </p>
          </div>

          <div class="column is-3">
            <p class="font_15 has_gray">
              Representación legal:
            </p>
          </div>
          <div class="column is-3">
            <p class="font_15 has_gray has-text-weight-semibold">
              {{ legalRepresentation || 'N/A' }}
            </p>
          </div>
        </template>
        <template v-else>
          <div class="column is-12">
            <p class="font_15 has_gray has-text-weight-semibold">
              Producto de administrador
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductUserInformation',
  data () {
    return {
      IS_PRODUCT: 1,
      IS_PENDING: 1,
      IS_APPROVED: 2,
      type: 1,
      name: null,
      quantity: null,
      statusProduct: 1,
      statusName: null,
      userName: null,
      phone: null,
      personTypeName: null,
      email: null,
      businessName: null,
      statusUser: false,
      legalRepresentation: null
    }
  },
  props: {
    dataDetail: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    spreadData () {
      this.type = this.dataDetail.type
      this.name = this.dataDetail.name
      this.quantity = this.dataDetail.quantity
      this.statusProduct = this.dataDetail.statusProduct
      this.statusName = this.dataDetail.status_name
      this.userName = this.dataDetail.userName
      this.isAdmin = this.dataDetail.is_admin
      this.phone = this.dataDetail.phone
      this.personTypeName = this.dataDetail.person_type_name
      this.email = this.dataDetail.email
      this.businessName = this.dataDetail.business_name
      this.statusUser = this.dataDetail.status_user
      this.legalRepresentation = this.dataDetail.legal_representative
    }
  },
  beforeMount () {
    this.spreadData()
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 5px;
    box-shadow: 0 3px 45px 0 rgba(188, 188, 222, 0.27);
    background-color: #fff;
  }
</style>
